import './App.css';
import Home from './components/home.jsx';
import { useEffect } from 'react';
import ScrollTop from './components/scrollTop.jsx';
import Header from './components/header.jsx';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MapaDigital from './components/construccion/Mapa Digital.jsx';
import ModernizacionAdm from './components/construccion/Modernizacion Adm.jsx';
import GestionInformes from './components/construccion/Gestion-Informes.jsx';
import InformacionPublica from './components/construccion/Informacion Publica.jsx';
import GobiernoAbierto from './components/construccion/Gobierno Abierto.jsx';
import SeccionesGral from './components/secciones/seccion general.jsx';
import Footer3 from './components/footer3.jsx';
import PortalObras from './components/construccion/Portal de obras.jsx';
import Lab from './components/construccion/LAB.jsx';
import PresupuestoParticipativo from './components/construccion/Presupuesto Participativo.jsx';
import GobiernoMunicipal from './components/construccion/Gobierno Municipal.jsx';
import Organigrama from './components/construccion/Organigrama.jsx';
import DatosEconomicos from './components/construccion/Datos Economicos.jsx';
import IndicadoresGestion from './components/construccion/IndicadoresDeGestion.jsx';
import Informes from './components/construccion/Informes.jsx';
import Cultura from './components/construccion/Cultura.jsx';
import GobiernoInformes from './components/construccion/Gobierno-Informes.jsx';
import SitEcoFin from './components/Datos Economicos/Sit. Eco-Fin.jsx';
import Presupuestos from './components/Datos Economicos/Presupuestos.jsx';
import StockDeuda from './components/Datos Economicos/Stock de Deuda.jsx';
import EjecucionPresup from './components/Datos Economicos/Ejecucion Presupuestaria.jsx';
import GobernanzaDatos from './components/construccion/Gobernanza Datos.jsx';
import EnConstruccion from './components/construccion/En Construccion.jsx';
import DocView from './components/Documentos/DocView.jsx';









function App() {

  useEffect(() => {
    const handleScroll = () => {
      const nav = document.querySelector('nav');
      if (nav) {
        if (window.scrollY > 50) {
          nav.classList.add('scrolled');
        } else {
          nav.classList.remove('scrolled');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
 
  return (


    <Router>
      
      <Routes>

        <Route path="/" element={
          <>
            <Header />
            <Home />
            <SeccionesGral />
            <Footer3 />
            <ScrollTop />
          </>
        } />

        <Route path="/mapa-digital" element={ <MapaDigital/> } />
        <Route path="/modernizacion-adm" element={<ModernizacionAdm/> } />
        <Route path="/gestion-informes" element={<GestionInformes/> } />
        <Route path="/informacion-publica" element={ <InformacionPublica/> } />
        <Route path="/gob-ar" element={<GobiernoAbierto/> } />
        <Route path="/portal-obras" element={<PortalObras/>} />
        <Route path="/LAB" element={<Lab/>} />
        <Route path="/presupuesto-participativo" element={<PresupuestoParticipativo/>} />
        <Route path="/gob-municipal" element={<GobiernoMunicipal/>}/>
        <Route path="/organigrama" element={<Organigrama/>}/>
        <Route path="/datos-economicos" element={<DatosEconomicos/>}/>
        <Route path="/indicadores-gestion" element={<IndicadoresGestion/>}/>
        <Route path="/informes" element={<Informes/>}/>
        <Route path="/datos-guias" element={<GobernanzaDatos/>}/>
        <Route path="/cultura" element={<Cultura/>}/>
        <Route path="/gob-informes" element={<GobiernoInformes/>}/>
        <Route path="/sit.eco-fin." element={<SitEcoFin/>}/>
        <Route path="/presupuestos" element={<Presupuestos/>}/>
        <Route path="/stock-deuda" element={<StockDeuda/>}/>
        <Route path="/ejecucion-presup" element={<EjecucionPresup/>}/>
        <Route path="/en-construccion" element={<EnConstruccion/>}/>
        <Route path="/documentos-internos/:fileName/:fileType" element={<DocView/>} />  {/* nombre del archivo/tipodeArch */}
       


      </Routes>



    </Router>


  );
}

export default App;





