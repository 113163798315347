import React from 'react';

function DocumentosInternos({ fileUrl, fileType }) {
  return (
    <div className="pdf-page-container">
      <div className="pdf-wrapper">
        {fileType === 'pdf' ? (
          <iframe
            src={fileUrl}
            width="100%"
            height="100%"
            title="Document Viewer"
            style={{ border: 'none' }}
          />
        ) : (
          <iframe
            src={fileUrl}
            width="100%"
            height="100%"
            title="HTML Viewer"
            style={{ border: 'none' }}
          />
        )}
      </div>
    </div>
  );
}

export default DocumentosInternos; /* DocumentosInternos recibe la URL del archivo
 y el tipo de archivo como props.
Renderiza el archivo utilizando un <iframe> basado 
en el tipo de archivo (pdf o html). */