import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import TituloAnimado from "../tituloAnimado";
import { Link } from "react-router-dom";



function InformacionPublica() {
  return (
    <>
      <div className="seccion2-container">
        <div className="header2-container">
          <p className="titular"> Información Pública </p>
          <Header2 />
        </div>


        <div className="seccion2-wrap">
          <div className="container texto2-seccion">
            <TituloAnimado extraClass="titulo-secciones2">Acceso a la información pública.</TituloAnimado>
            <br /><br />

            {/* <div className="seccion2-container">       
            <img className="imagenSeccion" alt="Circle" src="img/imagenSeccion8.jpg" />
        </div>  */}

            <p>
              El acceso a la información pública en San Isidro está regulado por la
              <span>
                <a href="https://boletines.sanisidro.gob.ar/digesto/1%20ASUNTOS%20VARIOS/1.1%20ACCION%20SOCIAL/1.31%20INFORMACION%20PUBLICA/1.31.1._Ordenanza_n%C2%BA_8042.pdf" target="_blank" className="link-color">  Ordenanza Nº 8.042/04 </a>
              </span>
              y su
              <span>
                <a href="https://boletines.sanisidro.gob.ar/digesto/1%20ASUNTOS%20VARIOS/1.1%20ACCION%20SOCIAL/1.31%20INFORMACION%20PUBLICA/1.31.2._Decreto_n%C2%BA_575-2005.pdf" target="_blank" className="link-color"> decreto reglamentario Nº 575/05</a>
              </span>.
              Este marco normativo indica que los pedidos de acceso a la información pública deben realizarse en formato escrito ante la Subdirección de Mesa de Entradas.
            </p>
            <p>
              Nos encontramos trabajando para que este procedimiento pueda ser más fácil para vos, permitiendo que cualquier persona pueda acceder a información pública al alcance de un click.
            </p>
            <p>

              Mientras tanto, podés realizar el trámite de manera presencial en Av. Centenario 77, San Isidro, en el subsuelo. El Municipio tiene 20 días hábiles para responder tu solicitud. Dicho plazo podrá prorrogarse por igual período, cuando la complejidad, volúmen de la información y/o fundado en la circunstancias de complejidad en la colección de la información.
            </p>

          </div >
          <br />  
          <div className="button-container">
          <Link to="/documentos-internos/Formulario-acceso-info-publica/pdf" target="_blank" rel="noopener noreferrer" >            
              <button type="button" className="btn btn-outline-success  btn-lg link-boton">
                Formulario
              </button>
            </Link>
          </div>
        </div>


        <Footer4 />
      </div>
    </>
  );
}

export default InformacionPublica;