import React from 'react';
import { useParams } from 'react-router-dom';
import DocumentosInternos from './DocumentosInternos';

function DocView() {
  const { fileName, fileType } = useParams();
  
  // Construye la URL del archivo
  let fileUrl = '';
  
  if (fileType === 'pdf') {
    fileUrl = `/pdfs/${fileName}.${fileType}`;
  } else if (fileType === 'html') {
    fileUrl = `/htmls/${fileName}.${fileType}`;
  }
  
  return (
    <DocumentosInternos
      fileUrl={fileUrl}
      fileType={fileType}
    />
  );
}

export default DocView;  /* En este código:

Se verifica el tipo de archivo (fileType) y se ajusta la URL del archivo según si es PDF o HTML.
La URL se construye de manera que apunte a la carpeta adecuada (/pdfs para PDFs y /htmls para HTMLs).

DocView recibe estos parámetros de la URL usando useParams de react-router-dom.
Construye la URL del archivo correcto en función de los parámetros recibidos (nombre del archivo y tipo de archivo).
Luego, pasa esta URL y el tipo de archivo al componente DocumentosInternos. */