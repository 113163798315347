import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import TituloAnimado from "../tituloAnimado";
import { Link } from "react-router-dom";




function Cultura() {
  return (
    <>
      <div className="seccion2-container">
        <div className="header2-container">
          <p className="titular"> Cultura </p>
          <Header2 />
        </div>

        <div className="seccion2-wrap">
          <div className="container texto2-seccion">
          {/*   <TituloAnimado extraClass="titulo-secciones2">Cultura</TituloAnimado>
            <br /><br /> */}
            
            <br /><br/>
          </div >

         {/*    BOTONERA */}

          <div className="button-container">
          <Link to="/documentos-internos/Informe-invierno-2024-Ex Ante/html" target="_blank" rel="noopener noreferrer">
              <button type="button" className="btn btn-outline-success  btn-lg link-boton cult">
              Vacaciones de Invierno - Ex Ante
              </button>
            </Link>
            <Link to="/documentos-internos/Informe-invierno-2024-expost_v2/html" target="_blank" rel="noopener noreferrer" >
              <button type="button" className="btn btn-outline-success  btn-lg link-boton cult ">
              Vacaciones de Invierno - Ex Post
              </button>
            </Link>

          </div>
        </div>
        <Footer4 />
      </div>
    </>
  );
}

export default Cultura;